@import '../_settngs/variables.css';

.App {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  --footer-height: 64px;

  box-sizing: border-box;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
  overflow: scroll;

  padding-bottom: var(--footer-height);
  text-align: center;
  color: var(--text-color);

  background-color:#0f0f0f;
  transition: color 250ms;
  transition: background-color 500ms ease-in-out;
}


.TopBar__Icon{
  color: #000;
}

.Button__Icon.undefined{
  color: #000;
}

.App__Body {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  padding-top: 64px;
}

.App_modal_open {
  height: 100vh;
  overflow-y: hidden;
}

.App_modal_open footer {
  display: none;
}

@media (max-width: 599px) {
  .App {
    --footer-height: 160px;
  }

  
}
