.challenges-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.challenges-hero {
  width: 100%;
  height: 1404px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.challenges-heading {
  width: 100%;
  height: 216px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.challenges-notification {
  width: 100%;
}
.challenges-component {
  text-decoration: none;
}
.challenges-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 70%;
  height: 1124px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.challenges-text {
  font-size: 40px;
  max-width: 535px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 56px;
}
.challenges-highlight {
  gap: var(--dl-space-space-halfunit);
  width: 70%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.challenges-text01 {
  font-size: 22px;
  max-width: 100%;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 27px;
}
.challenges-get-started {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #375EE0;
}
.challenges-content1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.challenges-heading1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.challenges-text26 {
  color: #C1FFFC;
  font-size: 64px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
}
.challenges-text27 {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  font-family: Poppins;
  line-height: 30px;
}
.challenges-hero-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  z-index: 15;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.challenges-ios-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}
.challenges-ios-btn:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.challenges-apple {
  width: 16px;
  height: 20px;
}
.challenges-caption {
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.challenges-android-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}
.challenges-android-btn:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.challenges-android {
  width: 18px;
  height: 20px;
}
.challenges-caption1 {
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
@media(max-width: 991px) {
  .challenges-content {
    gap: var(--dl-space-space-unit);
  }
  .challenges-get-started {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .challenges-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .challenges-heading1 {
    gap: var(--dl-space-space-halfunit);
  }
  .challenges-text26 {
    font-size: 40px;
  }
  .challenges-text27 {
    font-size: 18px;
  }
}
@media(max-width: 767px) {
  .challenges-text {
    font-size: 32px;
    line-height: 44px;
  }
  .challenges-text01 {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }
  .challenges-get-started {
    display: none;
  }
}
@media(max-width: 479px) {
  .challenges-hero {
    height: 2100px;
  }
  .challenges-content {
    width: 80%;
    height: 1829px;
    align-items: center;
  }
  .challenges-text {
    width: 100%;
    margin-top: 30%;
    font-size: 40px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 700;
    line-height: 56px;
  }
  .challenges-highlight {
    width: 408px;
    margin-top: 39px;
  }
  .challenges-text01 {
    width: 80%;
    height: 965;
    font-size: 16px;
    max-width: revert;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: 27px;
    padding-left: 20%;
  }
  .challenges-link {
    text-decoration: underline;
  }
  .challenges-hero-buttons {
    width: 100%;
    flex-direction: column;
  }
  .challenges-ios-btn {
    width: 100%;
  }
  .challenges-android-btn {
    width: 100%;
  }
}
