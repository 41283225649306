.SortChart {
  /* min-width: 320px; */
  /* max-width: 960px; */
  min-height: 218px;
  height: 40vh;
  padding: 1rem;

  background-color: var(--gray-300);

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  transition: 0.75s ease-in-out;
}
