.list-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.list-hero {
  width: 100%;
  height: 238px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home1-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #0f0f0f;
}

.list-h1{
  font-size: 60px;
  font-family: poppins;
  color: #0f0f0f;
  padding: 8px;
  background-color: #fff;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.popup-content p {
  margin: 0 0 20px;
}

.popup-content button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: white;
  cursor: pointer;
}


.container {
  text-align: center;
  padding: 20px;
}

.array-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.bracket {
  font-size: 24px;
  padding: 0 10px;
}

.array-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.array-item {
  margin: 0 5px;
  padding: 2px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #333;
  color: white;
}

/* Mobile-first responsive styling */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.section {
  margin-bottom: 20px;
  width: 100%;
}

.array-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.bracket {
  font-size: 24px;
  padding: 0 5px;
}

.array-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}
.array-container {
  display: flex;
  align-items: center;
}

.array-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0 10px;
}

.array-item {
  margin: 0 5px;
}

.small-font .array-item {
  font-size: 12px;
}

.method-container {
  margin: 30px 0;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.hey {
  padding: 5px;
  font-size: 14px;
}

.hello {
  padding: 5px 10px;
  font-size: 14px;
}

.array-item {
  margin: 0 5px;
  padding: 2px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #333;
  color: white;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.hey {
  flex: 1;
  padding: 5px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #555;
  color: white;
}

.hello {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
}

.hello:hover {
  background-color: #218838;
}



.hey {
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #030303;
  color: white;
}


button {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}


.home1-section {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  border-bottom-width: 1px;
}
.home1-section1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 1545px;
  display: flex;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-top-width: 1px;
}
.home1-header {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home1-right {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home1-cards {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: grid;
  grid-gap: var(--dl-space-space-threeunits);
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  grid-template-columns: auto auto auto;
}
.Button__Icon.undefined {
  color: #000;
  background-color: #fff;
}

.home1-footer {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home1-content {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home1-main-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home1-content1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home1-main {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home1-header1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home1-branding {
  width: 120px;
  object-fit: cover;
}
.home1-text {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  max-width: 260px;
  font-family: Poppins;
  line-height: 21px;
}
.home1-socials {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home1-link {
  display: contents;
}
.home1-link1 {
  display: contents;
}
.home1-link2 {
  display: contents;
}
.home1-categories {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home1-category {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home1-header2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home1-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home1-category1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 175px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home1-header3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home1-links1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home1-copyright {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home1-text14 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
.home1-subscribe {
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home1-main1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home1-heading {
  color: rgb(255, 255, 255);
  font-size: 20px;
  max-width: 275px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  line-height: 30px;
}
.home1-input-field {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
  background-color: #292929;
}
.home1-textinput {
  flex: 1;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  outline-style: none;
  background-color: rgba(217, 217, 217, 0);
}
.home1-buy {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #80FF44;
}
.home1-text15 {
  color: rgb(12, 16, 12);
  display: none;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home1-text16 {
  color: rgb(12, 16, 12);
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home1-notice {
  color: #686868;
  font-size: 14px;
  max-width: 400px;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: 21px;
}
.home1-copyright1 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home1-text19 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
.home1-container2 {
  display: contents;
}
@media(max-width: 991px) {
  .home1-section {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar-branding-logo{
    width: 55%;
  }

  .home1-section1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home1-cards {
    grid-template-columns: auto;
  }
  .home1-content {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home1-copyright {
    display: none;
  }
  .home1-copyright1 {
    display: flex;
  }
}
@media(max-width: 767px) {
  .home1-section1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home1-content1 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home1-main {
    gap: var(--dl-space-space-twounits);
  }
  .home1-categories {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home1-text16 {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
}

@media (max-width: 600px) {
  .array-list {
      font-size: 14px;
      gap: 5px;
  }

  .small-font .array-item {
      font-size: 10px;
  }

  .input-container {
      flex-direction: column;
      align-items: flex-start;
  }

  .hey, .hello {
      width: 100%;
      font-size: 12px;
  }
}

@media(max-width: 479px) {
  .home1-section1 {
    height: 809px;
  }

  
  .home-buy {
    position: relative;
    padding: 24px 48px;
    margin-left: -12%;
  }
  .home1-textinput {
    flex: 1;
  }
  .home-text30 {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .home1-text16 {
    color: rgb(12, 16, 12);
    display: none;
    font-size: 24px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
  }
}

.list-heading {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.list-notification {
  width: 100%;
}
.list-component {
  text-decoration: none;
}
.list-video {
  color: white;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #000000;
}
.list-banner-container {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
