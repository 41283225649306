.footer1-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.footer1-get-started {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #375EE0;
}
.footer1-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.footer1-heading {
  gap: var(--dl-space-space-unit);
  width: 616px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer1-text {
  color: rgb(193, 255, 252);
  width: 670px;
  font-size: 64px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
}
.footer1-text2 {
  color: #ffffff;
}
.footer1-text4 {
  color: rgb(255, 255, 255);
  width: 350px;
  font-size: 20px;
  text-align: center;
  font-family: "Poppins";
  line-height: 30px;
}
.footer1-text6 {
  color: #ffffff;
  font-weight: 700;
}
.footer1-text7 {
  color: #ffffff;
  font-weight: 700;
}
.footer1-hero-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  z-index: 15;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer1-android-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ffffff;
}
.footer1-android {
  width: 24px;
  height: 28px;
}
.footer1-caption {
  color: #000000;
  font-style: normal;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 500;
}
.footer1-caption:hover {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
}

@media(max-width: 991px) {
  .footer1-get-started {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .footer1-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .footer1-heading {
    gap: var(--dl-space-space-halfunit);
  }
  .footer1-text {
    font-size: 40px;
  }
  .footer1-text4 {
    font-size: 18px;
  }
}
@media(max-width: 767px) {
  .footer1-get-started {
    display: none;
  }
}
@media(max-width: 479px) {

  
  .footer1-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.footer1-get-started {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #375EE0;
}
.footer1-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.footer1-heading {
  gap: var(--dl-space-space-unit);
  width: 616px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer1-text {
  color: rgb(193, 255, 252);
  width: 479px;
  font-size: 34px;
  padding-right: 40px;
  padding-left: 40px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
}
.footer1-text2 {
  color: #ffffff;
}


.footer1-text4 {
  color: rgb(255, 255, 255);
  width: 350px;
  font-size: 20px;
  text-align: center;
  font-family: "Poppins";
  line-height: 30px;
}
.footer1-text6 {
  color: #ffffff;
  font-weight: 700;
}
.footer1-text7 {
  color: #ffffff;
  font-weight: 700;
}
.footer1-hero-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  z-index: 15;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer1-android-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ffffff;
}
.footer1-android {
  width: 24px;
  height: 28px;
}
.footer1-caption {
  color: #000000;
  font-style: normal;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 500;
}
.footer1-caption:hover {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
}

  
  .footer1-hero-buttons {
    width: 100%;
    flex-direction: column;
  }
  .footer1-android-btn {
    width: 100%;
  }
}
