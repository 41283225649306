.feature-feature {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 240px;
  transition: 0.3s;
  align-items: flex-start;
  user-select: none;
  border-color: #000;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
}
.feature-feature:hover {
  border-color: #ade2df;
}
.feature-image {
  height: 24px;
  object-fit: cover;
}
.feature-content {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-title {
  font-size: 17px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.feature-description {
  color: #5d5c5c;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
@media(max-width: 767px) {
  .feature-feature {
    gap: var(--dl-space-space-oneandhalfunits);
    max-width: 100%;
  }
  .feature-content {
    gap: var(--dl-space-space-halfunit);
  }
  .feature-title {
    font-size: 18px;
    line-height: 27px;
  }
  .feature-description {
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .feature-feature {
    padding: var(--dl-space-space-halfunit);
  }
}
