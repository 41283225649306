/* BLOCK */
.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  opacity: 0;
  background-color: white;
}

/* MODIFIERS */
.Backdrop_opaque {
  opacity: 0.8;
  backdrop-filter: saturate(0.2) blur(5px);
}
.Backdrop_dark {
  background-color: black;
}
.Backdrop_clickable {
  cursor: pointer;
}
