.Footer {
  box-sizing: border-box;
  min-height: 64px;
  width: 100%;
  padding: 1rem 20px;
  font-family: poppins;

  position: absolute;
  bottom: 0;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  text-align: center;
  word-spacing: 0.25rem;

  background-color: var(--background-color-2);
  box-shadow: 0px -2px 1px -1px rgba(0, 0, 0, 0.2);

  transition: background-color 500ms ease-in-out;
}

.Footer__Items {
  flex-grow: 1;
  display: inline-flex;
  justify-content: flex-end;
}

@media (max-width: 599px) {
  .Footer__Items {
    flex-flow: column;
    padding: 0.5rem;
  }
}

.Footer__Items * {
  padding: 0.25rem 1rem;
}

.Footer__Heart {
  color: var(--secondary);
  font-weight: 700;
}

.Footer__Link {
  color: var(--text-color);
  font-weight: 700;
  text-decoration: underline;
}

.Footer__Link:hover {
  color: var(--secondary-dark);
}
